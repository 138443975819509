import Heading from "../Heading";
import {useEffect, useRef, useState} from "react";
import {doGet} from "../../services/rest";
import MySpinner from "../MySpinner";
import moment from "moment/moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faMoneyBill,
    faPlus, faRefresh
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useSubscription} from "react-stomp-hooks";
import ClosedWorkOrders from "./ClosedWorkOrders";
import PopUp from "../PopUp";
import ItBilling from "./ItBilling";

function ItAdminDashboard() {
    const [myAppLocations, setMyAppLocations] = useState([]);
    const [workOrders, setWorkOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentLocation, setCurrentLocation] = useState("all");
    const navigate = useNavigate();
    const [closedWorkOrder, setClosedWorkOrder] = useState(null);
    const [remoteIfOpened, setRemoteIfOpened] = useState(null);
    const [showBilling, setShowBilling] = useState(JSON.parse(localStorage.getItem('showBilling')) || false);
    const [showOpenWorkOrder, setShowOpenWorkOrder] = useState(true)
    const [currentPage, setCurrentPage] = useState(0);
    const firstLoad = useRef(true)

    useSubscription("/it/work-orders", (message) => {
        const updatedOrder = JSON.parse(message.body);
        const specificId = updatedOrder.id;
        if(updatedOrder.closed){
            setClosedWorkOrder(updatedOrder)
            const filteredWorkOrdersContent = workOrders.content.filter(order => order.id !== specificId);
            setWorkOrders({ ...workOrders, content: filteredWorkOrdersContent });
        }else{
            setRemoteIfOpened(updatedOrder)
            const existingOrder = workOrders.content.find(order => order.id === specificId);
            if (existingOrder) {
                // If it exists, update it
                setWorkOrders({
                    ...workOrders,
                    content: workOrders.content.map(order =>
                        order.id === specificId ? updatedOrder : order
                    )
                });
            } else {

                let hasSpecificId = myAppLocations.some(obj => obj.id === updatedOrder.appLocations.id);
                if(hasSpecificId){
                    setWorkOrders({
                        ...workOrders,
                        content: [updatedOrder, ...workOrders.content]
                    });
                }
            }
        }
    })

    useEffect(() => {
        // save to local storage whenever showBilling changes
        localStorage.setItem('showBilling', showBilling);
    }, [showBilling]);

    useEffect(() => {
        doGet('it-admin/first-open').then(data => {
            setMyAppLocations(data.locations);
            setWorkOrders(data.current);
            setLoading(false);
            firstLoad.current = false;
        })
    },[])

    useEffect(() =>{
        if(!firstLoad.current){
            doGet(`it-admin/current?page=${currentPage}`).then(data => setWorkOrders(data));
        }
    },[currentPage])

    if (loading) {
        return (<>
            <Heading title={"Work Orders"} />
            <MySpinner />
        </>)
    }
    const formatDate = (dateTime) => {
        const date = moment(dateTime)
        if(!date.isValid()){
            return ""
        }
        return  date.format("MM/DD/yyyy h:mm a");
    }
    const formatDateOnly = (dateTime) => {
        if(!dateTime){
            return ""
        }
        return  moment(dateTime).format("MM/DD/yyyy");
    }
    const Colors = {
        deepPurpleAccent: '#9C27B0',
        red: '#F44336',
        orange: '#FF9800',
        yellow: '#FFEB3B',
        white: 'black',
    };

// Utility function to check if two dates are the same day
    const isSameDay = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };

    function rowColor(date, residentCompleted, type) {
        if (residentCompleted) {
            return Colors.deepPurpleAccent;
        }

        if (date) {

            try {
                const date1 = new Date(date);
                const today = new Date();

                switch (type) {
                    case "PRIOR":
                        if (isSameDay(date1, today) || today > date1) {
                            return Colors.red;
                        } else {
                            return Colors.orange;
                        }
                    case "ON":
                        if (isSameDay(date1, today)) {
                            return Colors.orange;
                        }
                        if (today > date1) {
                            return Colors.red;
                        }
                        return Colors.yellow;

                    case "AFTER":
                        if (today > date1) {
                            return Colors.orange;
                        }
                        return Colors.yellow;

                    case "":
                        if (isSameDay(date1, today)) {
                            return Colors.orange;
                        }
                        if (today > date1) {
                            return Colors.red;
                        }
                }
            } catch (e) {
                console.error(e);
            }
        }
        return Colors.white;
    }


    return(<>
        <Heading title={"Work Orders"} icons={[<div onClick={() => navigate("/it/pm")} style={{cursor: 'pointer', paddingRight: 10}}><FontAwesomeIcon icon={faRefresh} /></div>,
            <div style={{paddingRight: 10, cursor: "pointer"}} onClick={() => setShowBilling(true)}><FontAwesomeIcon
                icon={faMoneyBill}/></div>,
            <div style={{cursor: 'pointer'}}
                 onClick={() => navigate("/workorders/form", {state: {locations: myAppLocations}})}><FontAwesomeIcon
                icon={faPlus}/></div>
            ]}/>
            <div className={'inner-container-work-orders'} style={{paddingTop: 50}}>
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 20, alignItems: 'center', justifyContent: 'space-between'}}>
            <h3>Open Work Orders</h3>
                <div>
                    <FontAwesomeIcon onClick={() => setShowOpenWorkOrder(!showOpenWorkOrder)} style={{paddingRight: 10, cursor: 'pointer'}} icon={showOpenWorkOrder ? faArrowDown : faArrowUp} />
                <select className={'submitPost'} value={currentLocation} onChange={(e) => setCurrentLocation(e.target.value)}>
                    <option value="all">All</option>
                    {myAppLocations.map((location, index) => (
                        <option key={index} value={location.locationName}>{location.locationName}</option>
                    ))}
                </select>
                </div>
            </div>
                <div style={{
                    display: showOpenWorkOrder ? "block" : "none", //example max height
                }}>
            <table className={'workorder'} style={{width:'100%', fontSize: 12}}>
                <tr>
                    <th>Submitted Date</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Assigned To</th>
                    <th>Due Date</th>
                    <th>Submitted By</th>
                </tr>
                {workOrders.content
                    .filter(order => currentLocation === "all" || currentLocation === order.appLocations.locationName)
                    .map(order => (
                        <tbody key={order.id} onClick={() => navigate(`/workorders/edit/${order.id}`, {state: {locations: myAppLocations}})}>
                        <tr>
                            <td>{formatDate(order.createdDate)}</td>
                            <td>{order?.name ?? ''}</td>
                            <td>{order.subject}</td>
                            <td>{order?.assignedTo?.displayName ?? ""}</td>
                            <td style={{color : rowColor(order?.dueDate, false, order.dueDateType)}}>{formatDateOnly(order?.dueDate)}</td>
                            <td>{order?.submittedBy?.displayName}</td>
                        </tr>
                        </tbody>
                    ))}
            </table>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', paddingTop: 20}}>
                    {!workOrders.first && <div style={{flex: 1, textAlign: 'left'}}><FontAwesomeIcon style={{cursor: 'pointer'}} icon={faArrowLeft} onClick={() => setCurrentPage(currentPage-1)}/></div>}
                    {!workOrders.last && <div style={{flex: 1, textAlign: 'right'}}><FontAwesomeIcon style={{cursor: "pointer"}} icon={faArrowRight} onClick={() => setCurrentPage(currentPage+1)}/></div>}
                </div>
                <ClosedWorkOrders locations={myAppLocations} closedWorkOrders={closedWorkOrder} removeIfOpened={remoteIfOpened}/>
        </div>
        {showBilling && <PopUp
            content={<ItBilling myAppLocations={myAppLocations} />}
            handleClose={() => setShowBilling(false)}
        />}
    </>)
}

export default ItAdminDashboard
